import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import SiteLink from '../../SiteLink'
import ReferenceComponent from '../../references/reference.component'
import LearnMoreComponent from '../../learn-more/learn-more.component'
import heroImgMobile from '../../../assets/images/hero-banner-image-mobile@2x.jpg'
import heroImgDesktop from '../../../assets/images/banners/hero-banner-image-desktop.jpg'
import burdenGraphicBox from '../../../assets/images/hero-banner-text.svg'
import iconRisk from '../../../assets/images/icon-venn-diagram.svg'
import iconChart from '../../../assets/icons/icon-bar-chart.svg'
import { references } from './references'
import BrightcoveVideoComponent from '../../brightcove-video/brightcove-video.component'
import { videos } from '../../video-library/videos'
import { win } from '@src/app-utils'

import './homeb.component.scss'
import LeavingSiteModal from '../../leaving-site-modal/leaving-site-modal.component'

export class HomeComponentB extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showRegistrationModal: false,
      href: '',
      showLeavingSiteModal: false,
      leavingModalType: '',
    }

    this.handleHideModal = this.handleHideModal.bind(this)
    this.handleShowModal = this.handleShowModal.bind(this)
    this.fpoVideo = videos.fpoVideoB
  }

  handleHideModal() {
    this.setState({
      showRegistrationModal: false,
      showLeavingSiteModal: false,
    })
  }

  handleShowModal() {
    this.setState({
      showRegistrationModal: true,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  componentDidMount() {
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      document.getElementById('background-wrapper').className += ' safari-background'
    }

    const homepageVisited = win && localStorage.getItem('homepageVisited')

    if (!homepageVisited) {
      this.handleShowModal()
    }
  }
  openLink(link, type) {
    this.setState(() => {
      return { href: link, leavingModalType: type }
    }, this.handleShowModalClicked())
  }

  render() {
    const learnMoreContent = {
      className: 'learn-more-wrapper-white',
      title: <span>Hear from sleep experts about the <span className="no-wrap">long-term</span> management of narcolepsy</span>,
      link: '/resources/narcolepsy-expert-clinical-insights/',
      tracking: 'Home, click, expert insights - home - b',
      linkLabel: 'Explore expert insights'
    }


    return (
      <div className="page-wrapper home-b">
        <div
          id="background-wrapper"
          className="page-background-wrapper"
          aria-label="Tired all the time - Narcolepsy Link campaign"
        >
          <div className="hero-image-wrapper">
            <img
              src={heroImgMobile}
              className="mobile-hero-image"
              alt="Clouds young woman walking rocky terrain carrying boulders on back"
            />
            <img
              src={heroImgDesktop}
              className="desktop-hero-image"
              alt="Clouds young woman walking rocky terrain carrying boulders on back"
            />
          </div>
          <div className="burden-box">
            <img src={burdenGraphicBox} />
          </div>
        </div>

        <div className="primary-container">
          <div className="video-container">
            <div className="video-content">
              <div className="video">
                <BrightcoveVideoComponent
                  player={this.fpoVideo.player}
                  account={this.fpoVideo.account}
                  videoId={this.fpoVideo.videoId}
                  tracking={this.fpoVideo.tracking}
                  trackPercentComplete={this.fpoVideo.trackPercentComplete}
                  liveRampTracking={this.fpoVideo.liveRampTracking}
                  src={this.fpoVideo.src}
                />
              </div>
            </div>

            <LearnMoreComponent learnMoreContent={learnMoreContent} />
          </div>
        </div>

        <div className="cta-tiles-wrapper">
          <div className="cta-tile comorbidities-tile">
            <div className="cta-tile-content">
              <img src={ iconChart } alt="" className="cta-tile-icon"/>
              <div className="cta-tile-text">The prevalence of certain comorbidities is higher in patients with narcolepsy compared with matched controls<sup>3-5</sup></div>
              <SiteLink
                className="cta-tile-button btn-primary"
                to="/comorbidities-risk/cardiovascular-comorbidities/"
                tracking="Home, click, comorbidities - home - b"
                liveRampTracking={true}
              >
                <div className="cta-tile-button-text">
                  See the data&nbsp;&nbsp;
                </div>
                <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </SiteLink>
            </div>

          </div>
          <div className="cta-tile risk-factors-tile">
            <div className="cta-tile-content">
              <img src={ iconRisk } alt="" className="cta-tile-icon"/>
              <div className="cta-tile-text">Some risk factors for cardiovascular disease in the general population are modifiable with changes to the management plan<sup>6-15</sup></div>
              <SiteLink
                className="cta-tile-button btn-primary"
                to="/comorbidities-risk/risk/"
                tracking="Home, click, modifiable risks - home - b"
                liveRampTracking={true}
              >
                <div className="cta-tile-button-text">
                  Discover the risks&nbsp;&nbsp;
                </div>
                <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </SiteLink>
            </div>
          </div>
        </div>

        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
          modalType={this.state.leavingModalType}
        />
        <ReferenceComponent className="home-reference" referenceList={references} customFor="collapsible-b" />
      </div>
    )
  }
}

HomeComponentB.propTypes = {
  history: PropTypes.object,
}

export default HomeComponentB
