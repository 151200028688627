import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './reference.component.scss'

const ReferenceComponent = props => {
  return (
    <div className={`primary-container ${props.className || ''}`}>
      <div className="wrap-collabsible">
        <input id={props.customFor ? props.customFor : "collapsible"} className="toggle" type="checkbox"   />
        <label htmlFor={props.customFor ? props.customFor : "collapsible"} className="arrow-toggle">
          {referenceName(props.referenceList)}
          <div className="arrow-rotate">
            <FontAwesomeIcon icon="angle-up" />
          </div>
        </label>
        <div className="collapsible-content">
          <div className="content-inner">
            <ol>
              {props.referenceList.map((item, i) => {
                return (
                  <li className="content" key={i}>
                    {item.content} <i>{item.source}</i> {item.info}
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

function referenceName(references) {
  return references.length > 1 ? 'References' : 'Reference'
}
const { object, arrayOf, string } = PropTypes

ReferenceComponent.propTypes = {
  referenceList: arrayOf(object).isRequired,
  className: string,
  tracking: string,
  customFor: string
}

export default ReferenceComponent
